<template>
  <v-card>
    <v-card-title class="text-h6">
      {{ $t('labels.log_system') }}
      <v-spacer></v-spacer>
      <v-btn color="red darken-1" text @click="cancel">
        {{ $t('labels.close') }}
      </v-btn>
    </v-card-title>
    <v-card-text>
      <div>
        <v-simple-table fixed-header class="table-padding-2" height="calc(100vh - 250px)">
          <template v-slot:default>
            <tbody>
              <tr v-for="(item, index) in items" :key="`${index}_${item.id}`">
                <td style="vertical-align: top">
                  <div><b>{{ $t('labels.time') }}:</b> {{ formatDateTime(item.last_update, 'HH:mm:ss DD/MM/YYYY') }}</div>
                  <div><b>{{ $t('labels.status') }}:</b> {{ stateName[item.state] }}</div>
                  <div><b>{{ $t('labels.employee_last_change') }}:</b> {{ item.employee_changed_name }}</div>
                  <div><b>{{ $t('labels.user_change') }}:</b> {{ item.identity_changed_name }}</div>
                </td>
                <td>
                  <div v-for="(val, key) in item" :key="`${val}_${key}_${item._id}`">
                    <div class="my-1"
                      v-if="(!items[index + 1] || val !== items[index + 1][key]) && !['_id', 'id', 'employee_changed_name', 'identity_changed_name'].includes(key)">
                      <v-chip color="orange lighten-4" class="ml-0 mr-2 black--text" label small>{{ key }}</v-chip>
                      <span class="grey--text font-italic" v-if="!items[index + 1] || !items[index + 1][key]">N/A</span>
                      <span v-else class="font-italic">{{ items[index + 1][key] }}</span>
                      →
                      <span class="primary--text">{{ val }}</span>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import { httpClient } from "@/libs/http";
import { GOODS_ISSUE_STATE_NAME } from "@/libs/const";

export default {
  name: "LogOrderSystem",
  props: {
    idOrder: {
      type: Number,
      default: () => 0
    }
  },
  data: () => ({
    items: [],
    stateName: GOODS_ISSUE_STATE_NAME
  }),
  mounted() {
    this.getList()
  },
  methods: {
    cancel() {
      this.$emit('cancel')
    },
    async getList() {
      const { data } = await httpClient.post('/order-online-get-system-log', { id_order: this.idOrder })
      this.items = [...data]
    }
  }
}
</script>

<style scoped></style>
